export interface credentialsType {
  name: string;
  email: string;
  password: string;
}
export const LoginCred: credentialsType[] = [
  {
    name: "Rohit Sarkar",
    email: "admin@distronix.in",
    password: "admin",
  },
  {
    name: "NKDA Admin",
    email: "admin@nkda.in",
    password: "admin@nkda2022",
  },
  {
    name: "WBPCB Admin",
    email: "admin@wbpcb.in",
    password: "password",
  },
];

export const GoogleApiKey = "AIzaSyAut8wMgiwUlNdnp0BGlpbG4Jc0umurpM0";
