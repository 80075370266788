import { createSlice } from "@reduxjs/toolkit";

export const userSlices = createSlice({
  name: "user",
  initialState: { loggedStatus: false },
  reducers: {
    login: (state) => {
      state.loggedStatus = true;
    },
    logout: (state) => {
      state.loggedStatus = false;
    },
  },
});

export const { login, logout } = userSlices.actions;

export const userReducer = userSlices.reducer;
