import React, { useEffect, useRef, useState } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  excelHeaderQueryCellInfo,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
  PdfExportProperties,
  SelectionSettingsModel,
  AggregatesDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregateColumnDirective,
  Aggregate,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";

import moment from "moment";
import { nkda_base64 } from "../../assets/nkda_base64";
import { getWeighBridgeData } from "../../API/getWeighBridgeData";
import { getValue } from "@syncfusion/ej2-base";
import { MultiSelect } from "react-multi-select-component";
import { getVehicleListData } from "../../API/getVehicleList";
import Select from "react-select";
import { DateRange2 } from "../../components/DateRange/DateRange2";
import { getWeighBridgeVehicleListData } from "../../API/getWeighbridgeVehicleList";
import { CircularProgress } from "@material-ui/core";

const itemOptions = [
  { value: "Dry Solid Wast", label: "Dry Solid Waste" },
  { value: "Wet Solid Waste", label: "Wet Solid Waste" },
  { value: "General", label: "General" },
];

const transOptions = [
  { value: "Modern Nursery", label: "Modern Nursery" },
  { value: "Jafar Molla Enterprise", label: "Jafar Molla Enterprise" },
  { value: "General", label: "General" },
];

const WeighBridge = () => {
  const [GridData, setGridData] = useState<any>([]);
  const [Vehicles, setVehicles] = useState<any>([]);
  const [VehicleSelect, setVehicleSelect] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    value: "",
    label: "",
  });
  const [selectedTrans, setSelectedTrans] = useState({
    value: "",
    label: "",
  });

  const setDates = (s: string, e: string) => {
    setStartDate(s);
    setEndDate(e);
  };

  const getVehicleList = async () => {
    const result = await getWeighBridgeVehicleListData();
    const result2 = result.filter((j: any) => j !== null);
    const data = result2.map((i: string) => ({
      value: i,
      label: i,
    }));
    setVehicles(data);
    // result.map((item: any) => {
    //   setVehicles((prevState: any) => [
    //     ...prevState,
    //     { value: item.scu_id, label: item.vehicalNumber },
    //   ]);
    // });

    console.log("result ==>", result);
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  function timeConvert(n: number) {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours === 0) {
      return rminutes + " minutes";
    }
    return rhours + " hour(s) and " + rminutes + " minute(s)";
  }

  const getWBData = async () => {
    setDataLoading(true);
    const result = await getWeighBridgeData(
      startDate,
      endDate,
      VehicleSelect.map((i) => i.value),
      selectedItem.value,
      selectedTrans.value
    );
    if (result.length === 0) {
      setDataLoading(false);
    }
    const modData = result.map((item: any) => ({
      ...item,
      date_one: moment(item.date_one, "YYYYMMDDhhmmss").format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      date_two: moment(item.date_two, "YYYYMMDDhhmmss").format("DD/MM/YYYY"),
      time_taken: moment(item.date_two, "YYYYMMDDhhmmss").diff(
        moment(item.date_one, "YYYYMMDDhhmmss"),
        "minutes"
      ),
    }));
    setGridData(modData);
    setDataLoading(false);
  };

  const clearFilter = () => {
    setSelectedItem({
      value: "",
      label: "",
    });
    setSelectedTrans({
      value: "",
      label: "",
    });
    setVehicleSelect([]);
  };

  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName:
          `SWM Weighbridge Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".xlsx",
        header: {
          headerRows: 4,
          rows: [
            {
              cells: [
                {
                  colSpan: 11,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: `From: ${moment(startDate).format(
                    "DD/MM/YYYY"
                  )} To: ${moment(endDate).format("DD/MM/YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 11,
                  value: `Downloaded on ${moment().format("DD-MM-YYYY")}`,
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
    if (grid.current && args.item.text === "PDF Export") {
      const exportProperties: PdfExportProperties = {
        pageOrientation: "Landscape",
        pageSize: "A2",
        fileName:
          `SWM WeighBridge Report ${moment(startDate).format(
            "DD/MM/YYYY"
          )}-${moment(endDate).format("DD/MM/YYYY")} ` + ".pdf",
      };
      grid.current.pdfExport(exportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      image: nkda_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 1,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const toolbar = ["ExcelExport", "PdfExport", "Search"];
  const pageSettings: PageSettingsModel = { pageSize: 150 };

  // End DataGrid

  const cellSelected = (args: any) => {
    //@ts-ignore
    grid.current.filterByColumn(
      //@ts-ignore
      grid.current.getColumnByIndex(args.cellIndex.cellIndex).field,
      "equal",
      args.currentCell.innerHTML
    );
  };

  const settings: SelectionSettingsModel = {
    mode: "Cell",
  };

  const footerSum = (props: any) => {
    return <span>Total: {props.Sum.split("$")[1]}</span>;
  };
  const footerSum2 = (props: any) => {
    return <span>Total: {props.Sum.split("$")[1]}</span>;
  };
  const customizeCell = (args: any) => {
    if (args.column.field === "item_name" && args.data && args.cell) {
      args.cell.classList.add("underline");

      if (getValue("item_name", args.data) === "WET SOLID WASTE") {
        args.cell.style.color = "#028A02";
      } else {
        args.cell.style.color = "#86B3D3";
      }
    }

    if (
      (args.column.field === "vehicle_no" ||
        args.column.field === "transporter_name" ||
        args.column.field === "date_two") &&
      args.data &&
      args.cell
    ) {
      args.cell.classList.add("underline");
      args.cell.style.color = "blue";
    }
    if (args.column.field === "time_taken" && args.data && args.cell) {
      if (getValue("time_taken", args.data) < 120) {
        args.cell.classList.add("green");
      } else {
        args.cell.classList.add("red");
      }
    }
  };

  return (
    <div className="Container">
      <Sidebar />

      <div className="DataGridContainer overflow-x-hidden items-stretch flex-1 ">
        <h1>Reports / Weighbridge</h1>
        <div className="flex flex-row my-4 items-center ">
          <div className="flex flex-col">
            <p className="text-[#323743] font-semibold text-[0.95rem] pb-1">
              Vehicles
            </p>
            <MultiSelect
              // className="z-50"
              options={Vehicles}
              hasSelectAll
              value={VehicleSelect}
              onChange={setVehicleSelect}
              labelledBy="Select"
            />
          </div>
          <div className="flex flex-col ml-4 w-52">
            <p className="text-[#323743] font-semibold text-[0.95rem] pb-1">
              Item Name
            </p>
            <Select
              placeholder="Select Item"
              options={itemOptions}
              value={selectedItem}
              onChange={(v: any) => setSelectedItem(v)}
            />
          </div>
          <div className="flex flex-col ml-4 w-52">
            <p className="text-[#323743] font-semibold text-[0.95rem] pb-1">
              Transporter Name
            </p>
            <Select
              placeholder="Select Transporter Name"
              options={transOptions}
              value={selectedTrans}
              onChange={(v: any) => setSelectedTrans(v)}
            />
          </div>
          <div className="flex flex-col ml-4 w-[260px]">
            <p className="text-[#323743] font-semibold text-[0.95rem] pb-1">
              Date Range
            </p>
            <DateRange2 setDates={setDates} />
          </div>
          <button
            className=" bg-red-400 px-4 py-[0.38rem]  mt-7 ml-4 text-white font-semibold cursor-pointer hover:bg-red-500 hover:border-[#0064c9] hover:shadow-xl transition-[1s] "
            onClick={clearFilter}
          >
            <p>Clear Filter</p>
          </button>
          <button
            className="flex items-center bg-[#006edc] px-8 py-[0.32rem] border-2 border-[#006edc] mt-7 ml-4 text-white font-semibold cursor-pointer hover:bg-[#0064c9] hover:border-[#0064c9] hover:shadow-xl transition-[1s] "
            onClick={getWBData}
          >
            <p> Submit</p>
            {dataLoading && (
              <CircularProgress size={20} color="inherit" className="ml-2" />
            )}
          </button>
        </div>
        <div className="Grid">
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (grid.current = g)}
            filterSettings={filterOptions}
            toolbar={toolbar}
            dataSource={GridData}
            allowFiltering
            allowTextWrap
            allowSorting
            allowPaging={true}
            pageSettings={pageSettings}
            allowPdfExport
            allowExcelExport
            allowResizing={true}
            excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
            cellSelected={cellSelected}
            selectionSettings={settings}
            queryCellInfo={customizeCell}
          >
            <ColumnsDirective>
              {/* <ColumnDirective
                width="150"
                field="transaction_no"
                headerText="Transaction No."
              /> */}
              <ColumnDirective
                width="150"
                field="vehicle_no"
                headerText="Vehicle No."
              />
              <ColumnDirective
                width="150"
                field="item_name"
                headerText="Item Name"
              />
              <ColumnDirective
                width="150"
                field="transporter_name"
                headerText="Transporter Name"
              />
              {/* <ColumnDirective
                width="150"
                field="challan_no"
                headerText="Challan No."
              /> */}
              {/* <ColumnDirective
                width="150"
                field="date_one"
                headerText="Date One"
              /> */}
              {/* <ColumnDirective
                width="150"
                field="weight_one"
                headerText="Weight One (Kg)"
              /> */}
              <ColumnDirective
                width="140"
                field="date_two"
                headerText="Collection Date"
              />
              <ColumnDirective
                width="170"
                field="time_taken"
                headerText="Collection Time"
                valueAccessor={(field: any, data: any, column) =>
                  timeConvert(data[field])
                }
                allowFiltering={false}
              />
              {/* <ColumnDirective
                width="150"
                field="weight_two"
                headerText="Weight Two (Kg)"
              /> */}
              <ColumnDirective
                width="150"
                field="net_weight"
                headerText="Net Weight (Kg)"
                textAlign="Center"
                allowFiltering={false}
              />
              <ColumnDirective
                width="150"
                field="remarks"
                headerText="Remarks"
              />
            </ColumnsDirective>

            <AggregatesDirective>
              <AggregateDirective>
                <AggregateColumnsDirective>
                  <AggregateColumnDirective
                    field="weight_two"
                    type="Sum"
                    format="c1"
                    footerTemplate={footerSum}
                  >
                    {" "}
                  </AggregateColumnDirective>
                  <AggregateColumnDirective
                    field="net_weight"
                    type="Sum"
                    format="c1"
                    footerTemplate={footerSum2}
                  >
                    {" "}
                  </AggregateColumnDirective>
                </AggregateColumnsDirective>
              </AggregateDirective>
            </AggregatesDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                Filter,
                Sort,
                Resize,
                InfiniteScroll,
                Page,
                Aggregate,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default WeighBridge;
