import React, { useState } from "react";
import {
  HiStatusOnline,
  HiStatusOffline,
  HiOutlineTrash,
  HiOutlineTruck,
} from "react-icons/hi";
import "./components.css";
import { BiHide, BiShow } from "react-icons/bi";

const KPICards = (props: any) => {
  const [Toggle, setToggle] = useState(true);
  const data = props.VTSKPIData;
  return (
    <>
      <div className="KPIContainer">
        {Toggle && (
          <>
            <div className="Card">
              <div
                className="bg-white rounded-md p-1 w-10 z-30 "
                style={{
                  boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.4)",
                }}
              >
                <HiOutlineTruck
                  size={35}
                  color="#000000"
                  className="opacity-70"
                />
              </div>
              <div className="CardInfo">
                <h1>{data?.total_vehicles}</h1>
              </div>
              <p className="opacity-80">Vehicles Running</p>
            </div>
            <div className="Card">
              <div
                className="bg-white rounded-md p-1 w-10 z-30 "
                style={{
                  boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.4)",
                }}
              >
                <HiStatusOnline
                  size={35}
                  color="#000000"
                  className="opacity-70"
                />
              </div>
              <div className="CardInfo">
                <h1>{data?.total_active_vehicles}</h1>
              </div>
              <p className="opacity-80">Vehicles Online</p>
            </div>
            <div className="Card">
              <div
                className="bg-white rounded-md p-1 w-10 z-30 "
                style={{
                  boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.4)",
                }}
              >
                <HiStatusOffline
                  size={35}
                  color="#000000"
                  className="opacity-70"
                />
              </div>
              <div className="CardInfo">
                <h1>
                  {!!data && data?.total_vehicles - data?.total_active_vehicles}
                </h1>
              </div>
              <p className="opacity-80">Vehicles Offline</p>
            </div>
            <div className="Card">
              <div
                className="bg-white rounded-md p-1 w-10 z-30 "
                style={{
                  boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.4)",
                }}
              >
                <HiOutlineTrash
                  size={35}
                  color="#000000"
                  className="opacity-70"
                />
              </div>
              <div className="CardInfo">
                <h1>{!!data && data?.total_waste_collected}</h1>
              </div>
              <p className="opacity-80">Waste Collected Today</p>
            </div>
            {/* <div className="Card">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontSize: "1.09rem", opacity: 0.9 }}>
                  Waste Collected Today
                </p>
                <div
                  style={{
                    alignItems: "center",
                    padding: "3px",
                    borderRadius: "100%",
                    backgroundColor: "#d3d3d3",
                    opacity: 0.6,
                  }}
                >
                  <HiOutlineTrash
                    size={16}
                    color="#000000"
                    style={{ fontWeight: "bolder" }}
                  />
                </div>
              </div>

              <div className="CardInfo">
                <div>
                  <h1>
                    {data?.total_waste_collected}
                    <span style={{ fontSize: ".6em", opacity: 0.8 }}> kgs</span>
                  </h1>
                  <div className="flex item-center justify-between bg-gray-200 bg-opacity-20 p-1 pl-5 pr-5 rounded-md w-56 mt-1">
                    <div className="flex flex-col-reverse items-center">
                      <p style={{ fontSize: "0.8rem", opacity: 0.8 }}>Dry</p>
                      <p style={{ fontSize: "0.8rem", opacity: 0.8 }}>
                        {data?.total_waste_collected * 0.6}
                        <span style={{ fontSize: ".6rem", opacity: 0.8 }}>
                          {" "}
                          kgs
                        </span>
                      </p>
                    </div>
                    <div
                      style={{
                        height: "30px",
                        width: "0.5px",
                        backgroundColor: "#fff",
                        marginTop: "5px",
                      }}
                    />

                    <div className="flex flex-col-reverse items-center">
                      <p style={{ fontSize: "0.8rem", opacity: 0.8 }}>Solid </p>
                      <p style={{ fontSize: "0.8rem", opacity: 0.8 }}>
                        {data?.total_waste_collected * 0.4}
                        <span style={{ fontSize: ".6rem", opacity: 0.8 }}>
                          {" "}
                          kgs
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </>
        )}
      </div>
      <div className="KPIToggle" onClick={() => setToggle(!Toggle)}>
        {Toggle ? (
          <BiHide size={20} color={"#666666"} title="Hide Cards" />
        ) : (
          <BiShow size={20} color={"#666666"} title="Show Cards" />
        )}
      </div>
    </>
  );
};

export default KPICards;
