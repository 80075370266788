import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
} from "react-pro-sidebar";
import React, { useEffect, useState } from "react";
import "react-pro-sidebar/dist/css/styles.css";
import { BiTable, BiCompass, BiLogOut, BiTrash } from "react-icons/bi";
import { logout } from "../app/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu, GiNuclearWaste } from "react-icons/gi";
import { RiPinDistanceFill } from "react-icons/ri";
import { FaRoad, FaWeight, FaTruck } from "react-icons/fa";
import { MdMenu, MdMenuBook, MdShareLocation } from "react-icons/md";
import { GoAlert, GoSettings } from "react-icons/go";
import { FiSettings } from "react-icons/fi";

export const Logo = () => {
  return (
    <div className="sidebar-logo">
      <img src="logo.jpg" alt="logo" width={40} />
    </div>
  );
};

const Sidebar = () => {
  const [Collapse, setCollapse] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state: any) => state.user.loggedStatus);
  const navigate = useNavigate();
  const [Loggedout, setLoggedout] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [Loggedout]);

  return (
    <div className="sidebar flex-1">
      <ProSidebar collapsed={Collapse}>
        <SidebarHeader onClick={() => setCollapse(!Collapse)}>
          <div style={{ height: 70 }}></div>
          <Menu iconShape="square">
            <MenuItem icon={<GiHamburgerMenu />}>Menu</MenuItem>
          </Menu>
        </SidebarHeader>

        <Menu iconShape="square">
          <MenuItem icon={<GoSettings  />}>
            Dashboard <Link to="/dashboard" />
          </MenuItem>
          <MenuItem icon={<BiCompass />}>
            Map view <Link to="/mapview" />
          </MenuItem>
          <MenuItem icon={<MdShareLocation />}>
            Location History <Link to="/location-history" />
          </MenuItem>
          <SubMenu title="Reports" icon={<BiTable />} style={{ zIndex: 10 }}>
            <MenuItem icon={<RiPinDistanceFill />}>
              Daily Distance Travelled
              <Link to="/reports/daily-distance-travelled" />
            </MenuItem>
            <MenuItem icon={<FaRoad />}>
              Road Sweeper Report
              <Link to="/reports/road-sweeper" />
            </MenuItem>
            <MenuItem icon={<FaWeight />}>
              Weighbridge Report <Link to="/reports/weighbridge" />
            </MenuItem>
            <MenuItem icon={<FaWeight />}>
              Repeated Offender Report <Link to="/reports/RepetativeOffender" />
            </MenuItem>
          </SubMenu>
          <SubMenu title="Master Data" icon={<BiTable />}>
            <MenuItem icon={<FaTruck />}>
              Vehicles <Link to="/masterdata/vehicles" />
            </MenuItem>
          </SubMenu>

          <MenuItem
            icon={<BiLogOut />}
            onClick={() => {
              dispatch(logout());
              setLoggedout(true);
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default Sidebar;
