import axios from "axios";

export interface VTS_type {
  total_vehicles: number;
  total_active_vehicles: number;
  total_waste_collected: string;
  total_dry_waste_collected: string;
  total_wet_waste_collected: string;
}

export const getVTSKPIData = async () => {
  var config: any = {
    method: "get",
    url: "https://nkda.swm.distronix.in:3100/api/v2.1/get-vts-data",
    headers: {},
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response.data.data;
};
