import axios, { AxiosRequestConfig } from "axios";
import { useSnackbar } from "notistack";
import moment from "moment";

export const CreateVehicle = async (
  Vehicle_num: string,
  RegDate: any,
  DeviceId: string,
  VehicleType: string,
  Manufacturer: string,
  Contractor: string
) => {
  var data = JSON.stringify({
    vehicle_number: Vehicle_num,
    registration_date: moment(RegDate).format("YYYY-MM-DD HH:mm:ss"),
    device_id: DeviceId,
    vehicle_type: VehicleType,
    manufacturer: Manufacturer,
    contractor: Contractor,
  });

  var config: AxiosRequestConfig = {
    method: "post",
    url: "http://monster2.distronix.in:1100/api/vehical/createVehical",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      return error;
    });

  return result;
};
