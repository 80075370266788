import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { ReactComponent as Close } from "../assets/Modal/x.svg";
import { ReactComponent as DryWaste } from "../assets/Modal/garbage-re.svg";
import { ReactComponent as WetWaste1 } from "../assets/Modal/WetWaste1.svg";
import { ReactComponent as Delete } from "../assets/Modal/Delete.svg";
import DryWaste1 from "../assets/Modal/DryWaste1.png";
import WetWaste from "../assets/Modal/WetWaste.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  boxShadow: 24,
  px: 4,
  pb: 2,
};

export default function DashboardModal(props: any) {
  const handleClose = () => {
    props.onClose(!props?.isShow);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      // closeAfterTransition
      sx={{ background: "rgba(0, 0, 0, 0.43)", backdropFilter: "blur(4px)" }}
      open={props?.isShow === true ? true : false}
      onClose={handleClose}
    >
      <Fade in={props?.isShow === true ? true : false}>
        <Box
          sx={{ ...style, width: 400 }}
          style={{ outline: "none", borderRadius: 20 }}
          className="rounded-lg active:border-none focus:border-none"
        >
          <div className="space-y-4 pb-3">
            <div
              className=" absolute  -top-3 -right-3 cursor-pointer "
              onClick={handleClose}
            >
              <div className="bg-white p-2 rounded-full shadow-md">
                <Close className="w-3 h-3" />
              </div>
            </div>
            <h1 className="text-center text-2xl font-bold select-none">
              Waste Collected Today
            </h1>

            {/* Dry Waste */}
            <div className="rounded-2xl bg-gradient-to-r h-28 from-[#013b81] to-[#80b2ee] relative">
              <div className="flex items-start justify-start ">
                <img src={DryWaste1} className="w-48 h-28 " />
                <h1 className="absolute text-2xl text-white font-bold left-8 top-4">
                  {props?.dataModal[0]} kg
                  <br /> Dry Waste
                </h1>
              </div>
              <div className="flex items-end justify-end relative bottom-14 right-3">
                <DryWaste className="w-16 h-16 " />
              </div>
            </div>

            {/* Wet Waste */}
            <div className="rounded-2xl bg-gradient-to-r h-28 from-[#057a13] to-[#77cd81] relative">
              <div className="flex items-start justify-start ">
                <img src={WetWaste} className="w-48 h-28 " />
                <h1 className="absolute text-2xl text-white font-bold left-8 top-4">
                  {props?.dataModal[1]} kg
                  <br /> Wet Waste
                </h1>
              </div>
              <div className="flex items-end justify-end relative bottom-14 right-3">
                <WetWaste1 className="w-16 h-16 " />
              </div>
            </div>

            {/* General Waste */}
            <div className="rounded-2xl bg-gradient-to-r h-28  from-[#9ba301] to-[#dce037] relative">
              <div className="flex items-start justify-start ">
                <img src={WetWaste} className="w-48 h-28 " />
                <h1 className="absolute text-2xl text-white font-bold left-8 top-4">
                  {props?.dataModal[2]} kg
                  <br /> General Waste
                </h1>
              </div>
              <div className="flex items-end justify-end relative bottom-14 right-3">
                <Delete className="w-16 h-16 " />
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
