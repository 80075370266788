import { ObjectFlags } from "typescript";
import logo from "../assets/logo.png";

const Header = () => {
  return (
    <div className="Header">
      <div className="logo-text">
        <img src={logo} alt="logo" style={{objectFit:"contain",}} />
        {/* <p>
          NEWTOWN KOLKATA <br />
          DEVELOPMENT AUTHORITY
        </p> */}
      </div>
      <p> WBPCB SMART SOLID WASTE MANAGEMENT</p>
    </div>
  );
};

export default Header;
