import {
    configureStore,
    ThunkAction,
    Action,
    combineReducers,
  } from "@reduxjs/toolkit";
  import { userReducer } from "./userSlice";
  import { persistStore, persistReducer } from "redux-persist";
  import storage from "redux-persist/lib/storage";
  
  const rootReducer = combineReducers({
    user: userReducer,
  });
  
  const persistConfig = {
    key: "root",
    storage,
  };
  
  // @ts-ignore
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  
  export const store = configureStore({
    reducer: persistedReducer,
  });
  
  export const persistor = persistStore(store);
  
  export type AppDispatch = typeof store.dispatch;
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;
  