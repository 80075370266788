import React, { useRef } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  excelHeaderQueryCellInfo,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";

const WasteCollection = () => {
  // Data Grid
  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName: "Template Report" + ".xlsx",
        header: {
          headerRows: 8,
          rows: [
            {
              cells: [
                {
                  colSpan: 29,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 29,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `District: ${selectedDistrict}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Device: ${DeviceName}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            {
              cells: [
                {
                  colSpan: 29,
                  value:
                    "Parameter: PM1, PM25, PM10, AD0 NO2 OPT2, ADC NO2 OPT1, ADC SO2 OPT2, ADC SO2 OPT1, EXTERNAL TEMPERATURE, EXTERNAL HUMIDITY, POWER GOOD, RSSI, BIN ARRAY, M TO F ARRAY, SAMPLE PERIOD, SFR, INTERNAL TEMPERATURE ADC, INTERNAL HUMIDITY ADC, REJECT GLITCH, REJECT LONG TO F, REJECT RATIO, REJECT COUNT OUT OF RANGE, LASER STATUS, FAN FEEDBACK, TAMPER, SOLAR, POWER GOOD",
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `From: ${moment(StartDate).format(
            //         "DD/MM/YYYY hh:mm:ss"
            //       )} To: ${moment(EndDate).format("DD/MM/YYYY hh:mm:ss")}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Downloaded on ${moment().format(
            //         "DD-MM-YYYY hh:mm:ss A"
            //       )}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: DeviceName,
            //       style: {
            //         backColor: "#204FAC",
            //         fontColor: "#ffffff",
            //         fontSize: 13,
            //         hAlign: "Center",
            //       },
            //     },
            //   ],
            // },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      //   image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 2,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const toolbar = ["ExcelExport", "Search"];
  const pageSettings: PageSettingsModel = { pageSize: 150 };

  return (
    <div className="Container">
      <Sidebar />
      <div className="DataGridContainer">
        <h1>Reports / Premises Waste Collection</h1>
        <div className="Grid">
        <GridComponent
          toolbarClick={toolbarClick}
          ref={(g) => (grid.current = g)}
          filterSettings={filterOptions}
          toolbar={toolbar}
          allowFiltering
          allowSorting
          allowPaging={true}
          pageSettings={pageSettings}
          allowPdfExport
          allowExcelExport
          allowResizing={true}
          excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
        >
          <ColumnsDirective>
            <ColumnDirective width="150" field="pm1" headerText="Date" />
            <ColumnDirective width="150" field="pm25" headerText="Premise No." />
            <ColumnDirective width="150" field="pm10" headerText="Latitude" />
            <ColumnDirective
              width="150"
              field="adc_no2_opt2"
              headerText="Longitude"
            />
            <ColumnDirective
              width="150"
              field="adc_so2_opt2"
              headerText="Created At"
            />
            <ColumnDirective
              width="150"
              field="adc_so2_opt1"
              headerText="Updated At"
            />
          </ColumnsDirective>
          <Inject
            services={[
              Toolbar,
              ExcelExport,
              PdfExport,
              Filter,
              Sort,
              Resize,
              InfiniteScroll,
              Page,
            ]}
          />
        </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default WasteCollection;
