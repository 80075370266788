import React, { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import { Button } from "@mui/material";

const Filter = ({
  FilterToggle,
  VehiclesList,
  VehicleTypes,
  Contractors,
  setVehicleSelect,
  setContractSelect,
  setVehiclesTypeSelect,
  setSelectStatus,
  VehicleSelect,
  ContractSelect,
  VehiclesTypeSelect,
  SelectStatus,
}: any) => {
  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
      border: "none",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
      color: '#000',
    }),
  };
  return (
    FilterToggle && (
      <div className="FilterContainer">
        <div>
          <p
            style={{
              fontWeight: 500,
              color: "#fff",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            Vehicle
          </p>
          <MultiSelect
            options={VehiclesList}
            value={VehicleSelect}
            onChange={setVehicleSelect}
            labelledBy="Select"
          />
        </div>
        <div>
          <p
            style={{
              fontWeight: 500,
              color: "#fff",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            Type
          </p>
          <MultiSelect
            options={VehicleTypes}
            value={VehiclesTypeSelect}
            onChange={setVehiclesTypeSelect}
            labelledBy="Select"
          />
        </div>
        <div>
          <p
            style={{
              fontWeight: 500,
              color: "#fff",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            Contractor
          </p>
          <MultiSelect
            options={Contractors}
            hasSelectAll
            value={ContractSelect}
            onChange={setContractSelect}
            labelledBy="Select"
          />
        </div>
        <div style={{ color: "#fff" }}>
          <p
            style={{
              fontWeight: 500,
              color: "#fff",
              marginBottom: 5,
              marginTop: 5,
            }}
          >
            Device Status
          </p>
          <Select
            styles={selectStyle}
            options={[
              { value: "Online", label: "Online" },
              { value: "Offline", label: "Offline" },
            ]}
            value={
              SelectStatus === "Online"
                ? { value: "Online", label: "Online" }
                : SelectStatus === "Offline" && {
                    value: "Offline",
                    label: "Offline",
                  }
            }
            onChange={(e:any) => setSelectStatus(e.value)}
          />
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "2em" }}
        >
          {" "}
          {/* <button className="btn" style={{ marginTop: 15 }}>
            Apply
          </button> */}
          <button
            className="btn"
            style={{ marginTop: 20 }}
            onClick={() => {
              setSelectStatus("");
              setVehicleSelect([]);
              setVehiclesTypeSelect([]);
              setContractSelect([]);
            }}
          >
            Reset
          </button>
        </div>
      </div>
    )
  );
};

export default Filter;
