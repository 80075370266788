import axios, { AxiosRequestConfig } from "axios";

export const DeleteVehicle = async (id: string) => {
  var data = JSON.stringify({
    id: String(id),
  });

  var config: AxiosRequestConfig = {
    method: "delete",
    url: "http://monster2.distronix.in:1100/api/vehical/deleteVehicle",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const result = await axios(config)
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      return error;
    });

  return result;
};
