import axios from "axios";
import moment from "moment";

export const getPDF = async (
  devid: string,
  start_date: Date | null,
  end_date: any
) => {
  var data = JSON.stringify({
    devid: devid,
    start_time: moment(start_date).format("YY-MM-DD HH:mm:ss"),
    end_time: moment(end_date).format("YY-MM-DD HH:mm:ss"),
  });

  var config: any = {
    method: "post",
    url: `http://monster2.distronix.in:1100/api/report/locationHistoryPdf`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response;
};
