import axios from "axios";
import moment from "moment";

export const getRepeatedOffendersData = async (
  start_date: Date | null,
  end_date: Date | null
) => {
  var data = JSON.stringify({
    start_time: moment(start_date).format("YYYY/MM/DD") + " 00:00:00",
    end_time: moment(end_date).format("YYYY/MM/DD") + " 23:59:59",
  });

  var config: any = {
    method: "post",
    url: "http://monster2.distronix.in:1100/api/report/waybridgeRepetativeReport",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });
  return response.data.data;
};
