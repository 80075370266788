import axios from "axios";
import moment from "moment";

export interface DailyDistanceTraveledType {
  sl_no: number;
  date: string;
  vehicalNumber: string;
  numberOfDataPoints: number;
  distanceCovered: string;
}

export interface getDailyDistanceTraveledType {
  data: DailyDistanceTraveledType[];
  status: string;
}

export const getDailyDistanceTraveled = async (
  vehicleList: string[],
  start_date: Date | null,
  end_date: any
) => {
  var data = JSON.stringify({
    devid: vehicleList,
    start_date: moment(start_date).format("YYYY-MM-DD"),
    end_date: moment(end_date).format("YYYY-MM-DD"),
  });

  var config:any = {
    method: 'post',
    url: 'http://monster2.distronix.in:1100/api/report/dailyDistanceTraveled',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });
  return response.data.date;
};
