import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import KPICards from "../components/KPICards";
import DetailCard from "../components/DetailCard";
import Sidebar from "../components/Sidebar";
import ListCards from "../components/ListCards";
import Collapse from "@mui/material/Collapse";
import { FaList, FaFilter } from "react-icons/fa";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import Filter from "../components/Filter";
import { getVTSKPIData, VTS_type } from "../API/getVTSKPIData";
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { vehicleListType, getVehicleListData } from "../API/getVehicleList";
import moment from "moment";
import L from "leaflet";
import { Card } from "@mui/material";

export const sweeper = new L.Icon({
  iconUrl: "sweeper.png",
  iconSize: [35, 35],
});

export const truck = new L.Icon({
  iconUrl: "truck.png",
  iconSize: [35, 35],
});

const Home = () => {
  const popupElRef = useRef<any>(null);
  const mapProps = {
    center: {
      lat: 22.599006424,
      lng: 88.46426178,
    },
    zoom: 13,
  };
  const [VehicleSelect, setVehicleSelect] = useState<any[]>([]);
  const [Map, setMap] = useState<any>(null);
  const [ContractSelect, setContractSelect] = useState<any[]>([]);
  const [VehiclesTypeSelect, setVehiclesTypeSelect] = useState<any[]>([]);
  const [SelectStatus, setSelectStatus] = useState<any>();
  const [ListCollapse, setListCollapse] = useState(false);
  const [FilterToggle, setFilterToggle] = useState(false);
  const [VTSKPIData, setVTSKPIData] = useState<any>();
  const [vehicleList, setvehicleList] = useState<any[]>([]);
  const [Vehicles, setVehicles] = useState<any[]>([]);
  const [FilteredVehiclesList, setFilteredVehiclesList] = useState<any[]>([]);
  const [Selected, setSelected] = useState<string>();
  const getVTSKPI = async () => {
    const result = await getVTSKPIData();
    setVTSKPIData({
      total_vehicles: result.total_vehicles,
      total_active_vehicles: result.total_active_vehicles,
      total_waste_collected: result.total_waste_collected,
      total_dry_waste_collected: result.total_dry_waste_collected,
      total_wet_waste_collected: result.total_wet_waste_collected,
    });
  };

  const [VehicleTypes, setVehicleTypes] = useState<any[]>([]);
  const [Contractors, setContractors] = useState<any[]>([]);
  const getVehicleList = async () => {
    const result = await getVehicleListData();
    result.map((item: any) => {
      setvehicleList((prevState: any) => [
        ...prevState.filter((data: any) => data.scu_id !== item.scu_id),
        {
          ...item,
          status:
            moment.duration(moment().diff(item.lastActive)).hours() < 1
              ? "Online"
              : "Offline",
        },
      ]);
      setVehicles((prevState: any) => [
        ...prevState,
        { value: item.vehicalNumber, label: item.vehicalNumber },
      ]);
      setVehicleTypes((prevState: any) => [
        ...prevState.filter(
          (data: any) =>
            data.value?.toLowerCase() !== item.vehicle_type?.toLowerCase()
        ),
        {
          value: item.vehicle_type?.toLowerCase(),
          label: item.vehicle_type?.toLowerCase(),
        },
      ]);
      setContractors((prevState: any) => [
        ...prevState.filter(
          (data: any) =>
            data.value?.toLowerCase() !== item.contractor?.toLowerCase()
        ),
        {
          value: item.contractor?.toLowerCase(),
          label: item.contractor?.toLowerCase(),
        },
      ]);
    });
  };

  useEffect(() => {
    if (!!vehicleList) {
      vehicleList.map((item: any) => {
        setFilteredVehiclesList((prevState: any) => [
          ...prevState.filter((data: any) => data.scu_id !== item.scu_id),
          {
            ...item,
            status:
              moment.duration(moment().diff(item.lastActive)).hours() < 1
                ? "Online"
                : "Offline",
          },
        ]);
      });
    }
  }, [vehicleList]);

  useEffect(() => {
    getVTSKPI();
    getVehicleList();
  }, []);

  function ChangeView({ center, zoom }: any) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  const [CardDetails, setCardDetails] = useState<vehicleListType>();

  useEffect(() => {
    if (!!CardDetails) {
      setListCollapse(true);
      setSelected(CardDetails.scu_id);
      document.getElementById(CardDetails.scu_id)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [CardDetails]);

  useEffect(() => {
    const getOnlineDevices = () => {
      const onlineDevices = vehicleList?.filter((item: any) => {
        return moment.duration(moment().diff(item.lastActive)).hours() < 1;
      });
    };
    getOnlineDevices();
  }, [vehicleList]);

  useEffect(() => {
    const getOfflineDevices = () => {
      const offlineDevices = vehicleList?.filter((item: any) => {
        return moment.duration(moment().diff(item.lastActive)).hours() >= 1;
      });
      return offlineDevices;
    };
    getOfflineDevices();
  }, [vehicleList]);

  useEffect(() => {
    if (
      !!VehicleSelect ||
      !!ContractSelect ||
      !!VehiclesTypeSelect ||
      !!SelectStatus
    ) {
      const filteredList = vehicleList?.filter(
        (item: any) =>
          (!!VehicleSelect.length
            ? VehicleSelect.find(
                (data: any) => data.value === item.vehicalNumber
              )
            : true) &&
          (!!ContractSelect.length
            ? ContractSelect.find(
                (data: any) => data.value === item.contractor?.toLowerCase()
              )
            : true) &&
          (!!VehiclesTypeSelect.length
            ? VehiclesTypeSelect.find(
                (data: any) => data.value === item.vehicle_type?.toLowerCase()
              )
            : true) &&
          (!!SelectStatus ? item.status === SelectStatus : true)
      );
      setFilteredVehiclesList(filteredList);
    }
  }, [VehicleSelect, ContractSelect, VehiclesTypeSelect, SelectStatus]);

  return (
    <div className="Container">
      <Sidebar />
      <div className="Dashboard">
        <KPICards VTSKPIData={VTSKPIData} />
        {CardDetails && (
          <DetailCard
            CardDetail={CardDetails}
            setCardDetails={setCardDetails}
          />
        )}
        <div className="ListContainer">
          <div
            className="ListHeader"
            onClick={() => setListCollapse((prev) => !prev)}
          >
            <FaList style={{ marginTop: 3 }} /> Vehicle Lists
          </div>
          <Collapse in={ListCollapse}>
            <div className="List">
              {FilteredVehiclesList?.map((item) => (
                <div key={item.vehicalNumber} id={item.scu_id}>
                  <ListCards
                    vehicleList={item}
                    setCardDetails={setCardDetails}
                    CardDetails={CardDetails}
                    Selected={Selected}
                    popClose={popupElRef.current && popupElRef.current._close()}
                    popOpen={popupElRef.current && popupElRef.current._close()}
                  />
                </div>
              ))}
            </div>
          </Collapse>
        </div>
        <div
          className="FilterIcon"
          onClick={() => setFilterToggle(!FilterToggle)}
        >
          {" "}
          {FilterToggle ? <RiFilterOffFill /> : <RiFilterFill />}
        </div>
        <Filter
          VehicleSelect={VehicleSelect}
          setVehicleSelect={setVehicleSelect}
          ContractSelect={ContractSelect}
          setContractSelect={setContractSelect}
          VehiclesTypeSelect={VehiclesTypeSelect}
          setVehiclesTypeSelect={setVehiclesTypeSelect}
          SelectStatus={SelectStatus}
          setSelectStatus={setSelectStatus}
          FilterToggle={FilterToggle}
          VehiclesList={Vehicles}
          VehicleTypes={VehicleTypes}
          Contractors={Contractors}
        />
        <MapContainer
          style={{ height: "100vh", width: "100%" }}
          center={mapProps.center}
          zoom={mapProps.zoom}
          zoomControl={false}
          whenCreated={setMap}
        >
          {!!CardDetails && (
            <ChangeView
              center={[CardDetails?.lat, CardDetails?.long]}
              zoom={20}
            />
          )}
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <ZoomControl position="bottomright" />
          {FilteredVehiclesList?.map((item: any) => {
            return (
              <Marker
                key={item.scu_id}
                icon={
                  item.vehicle_type?.toLowerCase() === "truck" ? truck : sweeper
                }
                position={[
                  item.lat !== null && item.lat,
                  item.long !== null && item.long,
                ]}
                eventHandlers={{
                  click: () => {
                    setCardDetails(item);
                  },
                }}
              >
                <Popup ref={popupElRef}>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">
                      Vehicle Number{" "}
                    </span>{" "}
                    {item.vehicalNumber}
                  </p>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">Contractor </span>{" "}
                    {item.contractor}
                  </p>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">Battery </span>{" "}
                    {item.batteryParcent}%
                  </p>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">Signal </span>{" "}
                    {item.rssi}%
                  </p>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">Velocity </span>{" "}
                    {item.speed} km/h
                  </p>
                  <p style={{ margin: 0 }}>
                    <span className="font-semibold text-xs">Coordianates </span>{" "}
                    {item.lat}, {item.long}
                  </p>
                  <p style={{ margin: 0, fontStyle: "italic" }}>
                    {moment(
                      moment(item.lastActive).format("YYYY-MM-DD HH:mm:ss")
                    ).fromNow()}
                  </p>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>
      </div>
    </div>
  );
};

export default Home;
