import axios from "axios";

export interface allVehiclesDataType {
  id: number;
  device_id: number;
}

export interface getAllVehiclesDataType {
  status: string;
  total: number;
  data: allVehiclesDataType[];
}

export const getAllDeviceId = async () => {
  var config: any = {
    method: "get",
    url: `http://monster2.distronix.in:1100/api/vehical/getAllDevices`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });

  return response;
};
