import { CircularProgress } from "@mui/material";
import React from "react";
import { FaBan, FaTags, FaTrash, FaWifi, FaTruckPickup } from "react-icons/fa";
import { IoReloadSharp } from "react-icons/io5";
import { SHTdata } from "../../API/DashboardAPI/DashboardAPIS";
export interface Kpi {
  total_tags: number;
  total_cleaned: number;
  total_uncleaned: number;
  total_scaned_tags: number;
  scaned_by_swm: number;
  scaned_by_mosquito: number;
  scaned_by_electrical: number;
  total_vts_bincheck: number;
}

const ShtCards = () => {
  const [shtData, setShtData] = React.useState<Kpi>();

  const getShtData = async () => {
    const shtAPIdata = await SHTdata();
    if (shtAPIdata) {
      setShtData(shtAPIdata.kpi);
    }
  };

  React.useEffect(() => {
    getShtData();
  }, []);

  return (
    <>
      {!shtData ? (
        <div className="h-36 items-center justify-center flex">
          <CircularProgress />
        </div>
      ) : (
        <div className="grid grid-cols-5 gap-4 pl-1   pr-4">
          <div
            className={`h-36 bg-[#e47a26] flex flex-col justify-between  shadow-2xl rounded-lg py-4 px-4`}
          >
            <div className="flex justify-between  ">
              {/* Total Tags */}

              <FaTags size={25} color={"#fff"} />
              <h1 className="text-2xl font-bold text-white">
                {shtData!.total_tags}
              </h1>
            </div>

            <h1 className="text-xl font-semibold relative text-white text-right ">
              Total Tags
            </h1>
          </div>
          {/* ------------Total Scanned Tags -------------- */}
          <div
            className={`h-36 bg-[#e1be24] flex flex-col justify-between  shadow-2xl rounded-lg py-4 px-4`}
          >
            <div className="flex justify-between  ">
              <FaWifi size={25} color={"#fff"} />
              <h1 className="text-2xl font-bold text-white">
                {shtData!.total_scaned_tags}
              </h1>
            </div>

            <h1 className="text-xl font-semibold relative text-white text-right ">
              Total Scanned Tags
            </h1>
          </div>

          {/* ------------Total Bins Cleaned -------------- */}
          <div
            className={`h-36 bg-[#36bea6] flex flex-col justify-between  shadow-2xl rounded-lg py-4 px-4`}
          >
            <div className="flex justify-between  ">
              <FaTrash size={25} color={"#fff"} />
              <h1 className="text-2xl font-bold text-white">
                {shtData!.total_vts_bincheck}
              </h1>
            </div>

            <h1 className="text-xl font-semibold relative text-white text-right ">
              Total Bins Cleaned
            </h1>
          </div>

          {/* ----------------Total Tags not Scanned------------------ */}

          <div
            className={`h-36 bg-[#e61f26] flex flex-col justify-between  shadow-2xl rounded-lg py-4 px-4`}
          >
            <div className="flex justify-between  ">
              <FaBan size={25} color={"#fff"} />
              <h1 className="text-2xl font-bold text-white">
                {shtData!.total_uncleaned}
              </h1>
            </div>

            <h1 className="text-xl font-semibold relative text-white text-right ">
              Total Tags not Scanned
            </h1>
          </div>

          {/* ----------------Total Segregated Waste------------------ */}

          <div
            className={`h-36 bg-[#3366ff] flex flex-col justify-between  shadow-2xl rounded-lg py-4 px-4`}
          >
            <div className="flex justify-between  ">
              <FaTruckPickup size={25} color={"#fff"} />
              <h1 className="text-2xl font-bold text-white">
                {shtData!.scaned_by_swm}
              </h1>
            </div>

            <h1 className="text-xl font-semibold relative text-white text-right ">
              Total Segregated Waste
            </h1>
          </div>
        </div>
      )}
    </>
  );
};

export default ShtCards;
