import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { DeleteVehicle } from "../API/MasterDataCrud/Vehicles/Delete";

const VehicleDelete: React.FC<{
  setToggleModal: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  Grid: any;
  getReadVehiclesData: any;
}> = ({ setToggleModal = () => {}, data, Grid, getReadVehiclesData }) => {
  const [ID, setID] = useState<string>(data?.id);
  const { enqueueSnackbar } = useSnackbar();

  console.log(ID);

  const handleDelete = async () => {
    const result = await DeleteVehicle(ID);
    if (result?.status === 202) {
      enqueueSnackbar("Data Deleted Sucessfully", { variant: "success" });
      setToggleModal(false);
      getReadVehiclesData();
      Grid.refresh();
    } else {
      enqueueSnackbar("Data failed to Delete. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <div className="bg-white w-2/6 px-5 py-5 absolute">
      <p className="pb-5">
        Clicking on 'Delete' will remove data permanently, if you are not sure
        click on 'Go back'.
      </p>
      <div className="flex flex-row gap-10 justify-between">
        <div
          onClick={() => setToggleModal(false)}
          className="mt-2 bg-blue-400 text-white px-3 py-2 w-40 text-center self-end font-semibold cursor-pointer shadow-md hover:shadow-lg"
        >
          Go Back
        </div>
        <div
          onClick={handleDelete}
          className="mt-2 bg-red-400 text-white px-3 py-2 w-40 text-center self-end font-semibold cursor-pointer shadow-md hover:shadow-lg"
        >
          Delete Row {ID}
        </div>
      </div>
    </div>
  );
};

export default VehicleDelete;
