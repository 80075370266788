import moment from "moment";
import Popover from "@mui/material/Popover";

export interface Kpi {
  total_tags: number;
  total_cleaned: number;
  total_uncleaned: number;
  total_scaned_tags: number;
  scaned_by_swm: number;
  scaned_by_mosquito: number;
  scaned_by_electrical: number;
  total_vts_bincheck: number;
}

// export interface data {
//   sum: string;
//   item_name: string;
// }

// export interface areaData {
//   date: string;
//   data: data[];
// }

// export interface graphDataInterface {
//   status: string;
//   data: areaData[];
// }

export interface shtDataInterface {
  status: string;
  kpi: Kpi;
}

export const SHTdata = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const shtdata = await fetch(
    `https://api.sht.distronix.in:4443/api/v1.1/avarage/kpi?start_date=${moment().format(
      "YYYY-MM-DD"
    )}&end_date=${moment().format("YYYY-MM-DD")}`,
    //@ts-ignore
    requestOptions
  )
    .then((response) => response.json())
    .then((result: shtDataInterface) => {
      return result;
    })
    .catch((error) => {
      return error;
    });

  return shtdata;
};
export interface areaData {
  date: string;
  dry_solid_waste: number;
  wet_solid_waste: string;
  general: number;
}

export interface graphDataInterface {
  status: string;
  data: areaData[];
}

export const areaChartData = async ({ startDate, endDate }: any) => {
  console.log(endDate, startDate);
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const data = await fetch(
    `http://monster2.distronix.in:1100/api/report/getWestcollect?start_date=${endDate}&end_date=${startDate}`,
    //@ts-ignore
    requestOptions
  )
    .then((response) => response.json())
    .then((result: graphDataInterface) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
  return data;
};

// Today Dounts data



//Date Change apis
export const donutsDataAPIDateAdded = async (dateSelect : any) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  const data = await fetch(
    `http://monster2.distronix.in:1100/api/report/getWestcollect?start_date=${dateSelect}&end_date=${dateSelect}`,
    //@ts-ignore
    requestOptions
  )
    .then((response) => response.json())
    .then((result: graphDataInterface) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
  return data;
};
