import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Sidebar from "../components/Sidebar";
import { BiPlay, BiPause } from "react-icons/bi";
import { AiFillForward, AiFillBackward } from "react-icons/ai";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Select from "react-select";
import { getVehicleListData } from "../API/getVehicleList";
import moment from "moment";
import { getDataPoints, getDataPointsType } from "../API/getDataPoints";
import { getPDF } from "../API/getPDF";

export const markerIcon = new L.Icon({
  iconUrl: "marker.svg",
  iconSize: [35, 35],
});

export const markerSelected = new L.Icon({
  iconUrl: "markerSelected.svg",
  iconSize: [35, 35],
});

const LocationHistory = () => {
  const dispatch = useDispatch();
  const [start_date, setstart_date] = useState<Date>(
    new Date(moment().subtract(1, "days").startOf("day").format())
  );
  const [allDataPoints, setallDataPoints] = useState<any[]>([]);
  const [end_date, setend_date] = useState<Date>(new Date(moment().format()));
  const [TogglePlay, setTogglePlay] = useState(false);
  const [Map, setMap] = useState<any>();
  const [Vehicles, setVehicles] = useState<any[]>([]);
  const [LocationHistoryData, setLocationHistoryData] =
    useState<getDataPointsType>();

  const mapProps = {
    center: {
      lat: 22.562166,
      lng: 88.409355,
    },
    zoom: 14,
  };

  const selectStyle = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: "0px",
    }),
  };

  const getVehicleList = async () => {
    const result = await getVehicleListData();
    result.map((item: any) => {
      setVehicles((prevState: any) => [
        ...prevState,
        { value: item.scu_id, label: item.vehicalNumber },
      ]);
    });
  };

  useEffect(() => {
    getVehicleList();
  }, []);

  const speed = [
    { value: 0, label: "Skip 0" },
    { value: 10, label: "Skip 10" },
    { value: 50, label: "Skip 50" },
    { value: 100, label: "Skip 100" },
  ];

  const [VehicleSelect, setVehicleSelect] = useState<any>();
  const [Playspeed, setPlayspeed] = useState<any>({
    value: 0,
    label: "Skip 0",
  });
  const [TempMarker, setTempMarker] = useState<any>({
    lat: 22.599006424,
    lng: 88.46426178,
  });
  const [LocationIndex, setLocationIndex] = useState(0);

  const increment = () => {
    let add = 0;
    if (Playspeed?.value === 10) {
      setLocationIndex(LocationIndex + 10);
      add = 10;
    } else if (Playspeed?.value === 50) {
      setLocationIndex(LocationIndex + 50);
      add = 50;
    } else if (Playspeed?.value === 100) {
      setLocationIndex(LocationIndex + 100);
      add = 100;
    } else {
      setLocationIndex(LocationIndex + 1);
      add = 1;
    }
    return add;
  };

  const decrement = () => {
    let deduct = 0;
    if (Playspeed?.value === 10) {
      setLocationIndex(LocationIndex - 10);
      deduct = 10;
    } else if (Playspeed?.value === 50) {
      setLocationIndex(LocationIndex - 50);
      deduct = 50;
    } else if (Playspeed?.value === 100) {
      setLocationIndex(LocationIndex - 100);
      deduct = 100;
    } else {
      setLocationIndex(LocationIndex - 1);
      deduct = 1;
    }
    return deduct;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (TogglePlay) {
        let add = increment();
        if (LocationIndex + add < allDataPoints.length) {
          setTempMarker(allDataPoints[LocationIndex + add]);
          Map.flyTo([
            allDataPoints[LocationIndex + add]?.lat,
            allDataPoints[LocationIndex + add]?.lng,
          ]);
        } else {
          setTogglePlay(false);
          setLocationIndex(0);
          setTempMarker(allDataPoints[0]);
        }
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [TogglePlay, LocationIndex]);

  const forward = () => {
    let add = increment();
    if (LocationIndex + add < allDataPoints.length) {
      setTempMarker(allDataPoints[LocationIndex + add]);
      Map.flyTo([
        allDataPoints[LocationIndex + add]?.lat,
        allDataPoints[LocationIndex + add]?.lng,
      ]);
    } else {
      setTogglePlay(false);
      setLocationIndex(0);
      setTempMarker(allDataPoints[0]);
    }
  };

  const backward = () => {
    let deduct = decrement();
    if (LocationIndex - deduct >= 0) {
      setTempMarker(allDataPoints[LocationIndex - deduct]);
      Map.flyTo([
        allDataPoints[LocationIndex - deduct]?.lat,
        allDataPoints[LocationIndex - deduct]?.lng,
      ]);
    } else {
      setTogglePlay(false);
      setLocationIndex(0);
      setTempMarker(allDataPoints[0]);
    }
  };
  useEffect(() => {
    if (!!VehicleSelect) {
      const getDataPoinsFunc = async () => {
        const result = await getDataPoints(
          VehicleSelect.value,
          start_date,
          end_date
        );
        setLocationHistoryData(result);
      };
      getDataPoinsFunc();
    }
    setPdfStatus(0);
  }, [start_date, end_date, VehicleSelect]);

  const [PdfStatus, setPdfStatus] = useState(0);
  const downloadPDF = async () => {
    setPdfStatus(1);
    const result = await getPDF(VehicleSelect.value, start_date, end_date);
    if (result.status === 200) {
      setPdfStatus(0);
      window.open(result.data.path, "_blank")?.focus();
    }
  };

  const handleStartDatePicker = (e: any) => {
    setstart_date(new Date(moment(e.value).format()));
  };
  const handleEndDatePicker = (e: any) => {
    setend_date(new Date(moment(e.value).format()));
  };

  useEffect(() => {
    setallDataPoints([]);
    if (!!LocationHistoryData && LocationHistoryData.data.length > 0) {
      LocationHistoryData.data.forEach((item: any) => {
        setallDataPoints((prevState: any) => [
          ...prevState,
          { lat: item.latitude, lng: item.longitude, time: item.createdAt },
        ]);
      });
      setTempMarker({
        lat: LocationHistoryData.data[0].latitude,
        lng: LocationHistoryData.data[0].longitude,
      });
    }
  }, [LocationHistoryData]);

  return (
    <div className="Container">
      <Sidebar />
      <div className="Dashboard">
        {/* Form Begin */}
        <div className="rightPanel">
          <div className="AnimatedFilter">
            <div>
              <p
                style={{ fontWeight: 500, color: "#b0b0b0", marginBottom: 10 }}
              >
                Vehicle
              </p>
              <Select
                styles={selectStyle}
                options={Vehicles}
                value={VehicleSelect}
                onChange={setVehicleSelect}
              />
            </div>
            <div>
              <p
                style={{
                  fontWeight: 500,
                  color: "#b0b0b0",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Start Date\Time
              </p>

              <DateTimePickerComponent
                id="datetimepicker"
                value={start_date}
                onChange={(e: any) => handleStartDatePicker(e)}
              />
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  value={start_date}
                  onChange={(e: any) => handleStartDatePicker(e)}
                  renderInput={(params:any) => <TextField {...params} />}
                  InputProps={{
                    style: {
                      paddingRight: 15,
                      height: 28,
                      margin: 0,
                    },
                  }}
                />
              </LocalizationProvider> */}
            </div>
            <div>
              <p
                style={{
                  fontWeight: 500,
                  color: "#b0b0b0",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                End Date\Time
              </p>
              <DateTimePickerComponent
                id="datetimepicker"
                value={end_date}
                onChange={(e: any) => handleEndDatePicker(e)}
              />
            </div>
            <div>
              <p
                style={{
                  fontWeight: 500,
                  color: "#b0b0b0",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                Playback Speed
              </p>
              <Select
                styles={selectStyle}
                options={speed}
                defaultValue={speed[0]}
                value={Playspeed}
                onChange={setPlayspeed}
              />
            </div>
            {/* <div
            style={{ display: "flex", flexDirection: "row", columnGap: "2em" }}
          >
            {" "}
            <button className="btn" style={{ marginTop: 15 }}>
              Apply
            </button>
            <button
              className="btn"
              style={{ marginTop: 15, backgroundColor: "#fff", color: "#000" }}
            >
              Reset
            </button>
          </div> */}
            <p onClick={downloadPDF} className="Link mt-4">
              {PdfStatus === 0 ? "Download PDF" : PdfStatus === 1 && "Downloading..."}
            </p>
          </div>
          {/* Card Begins */}
          {!!VehicleSelect && (
            <>
              <div className="KeyInfo">
                <div className="KeyInfoBlock">
                  <div>
                    <p style={{ fontSize: "1.3em" }}>
                      {LocationHistoryData?.total}
                    </p>
                    <p style={{ fontSize: ".9em" }}>Data Points</p>
                  </div>
                  <div>
                    <p style={{ fontSize: "1.3em" }}>
                      {LocationHistoryData?.totalDistance} km
                    </p>
                    <p style={{ fontSize: ".9em" }}>Distance Covered</p>
                  </div>
                </div>
                <div className="KeyInfoBlock">
                  <div>
                    <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                      {TempMarker?.lat}
                    </p>
                    <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                      Latitude
                    </p>
                  </div>
                  <div>
                    <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                      {TempMarker?.lng}
                    </p>
                    <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                      Longitude
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                    Timestamp
                  </p>
                  <p style={{ fontSize: ".85em", fontWeight: "normal" }}>
                    {moment(TempMarker?.time).format("MMMM Do YYYY, h:mm:ss a")}
                  </p>
                </div>
              </div>
              <div className="Player">
                <AiFillBackward
                  size={35}
                  style={{ cursor: "pointer" }}
                  onClick={() => backward()}
                />
                {TogglePlay ? (
                  <BiPause
                    size={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => setTogglePlay(!TogglePlay)}
                  />
                ) : (
                  <BiPlay
                    size={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setTogglePlay(!TogglePlay);
                    }}
                  />
                )}
                <AiFillForward
                  size={35}
                  style={{ cursor: "pointer" }}
                  onClick={() => forward()}
                />
              </div>
            </>
          )}

          {/* Card Ends */}
        </div>
        {/* Form End */}

        <MapContainer
          style={{ height: "100vh", width: "100%" }}
          center={TempMarker}
          zoom={mapProps.zoom}
          zoomControl={false}
          whenCreated={(map) => {
            setMap(map);
          }}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {!!allDataPoints[0] &&
            allDataPoints.map((item: any, index: any) => {
              return (
                <Marker key={index} icon={markerIcon} position={item}></Marker>
              );
            })}

          {!!allDataPoints[0] && (
            <>
              <Marker
                icon={markerSelected}
                position={TempMarker}
                zIndexOffset={9999}
              ></Marker>

              <Polyline color="#235DAE" positions={allDataPoints} />
            </>
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default LocationHistory;
