import React, { useRef, useState } from "react";
import {
  Inject,
  Toolbar,
  ExcelExport,
  Filter,
  Sort,
  FilterSettingsModel,
  SortSettingsModel,
  PdfExport,
  InfiniteScroll,
  PageSettingsModel,
  ExcelExportProperties,
  excelHeaderQueryCellInfo,
  Page,
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Resize,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import Sidebar from "../../components/Sidebar";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";

const WeighBridgeV2 = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Data Grid
  const grid = useRef<GridComponent | null>(null);
  const toolbarClick = (args: ClickEventArgs) => {
    if (grid.current && args.item.text === "Excel Export") {
      const excelExportProperties: ExcelExportProperties = {
        fileName: "Template Report" + ".xlsx",
        header: {
          headerRows: 8,
          rows: [
            {
              cells: [
                {
                  colSpan: 29,
                  value: "WBPCB",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 18,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            {
              cells: [
                {
                  colSpan: 29,
                  value: "West Bengal Pollution Control Board",
                  style: {
                    backColor: "#cde6f7",
                    fontColor: "#000000",
                    fontSize: 16,
                    hAlign: "Center",
                    bold: true,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `District: ${selectedDistrict}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Device: ${DeviceName}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            {
              cells: [
                {
                  colSpan: 29,
                  value:
                    "Parameter: PM1, PM25, PM10, AD0 NO2 OPT2, ADC NO2 OPT1, ADC SO2 OPT2, ADC SO2 OPT1, EXTERNAL TEMPERATURE, EXTERNAL HUMIDITY, POWER GOOD, RSSI, BIN ARRAY, M TO F ARRAY, SAMPLE PERIOD, SFR, INTERNAL TEMPERATURE ADC, INTERNAL HUMIDITY ADC, REJECT GLITCH, REJECT LONG TO F, REJECT RATIO, REJECT COUNT OUT OF RANGE, LASER STATUS, FAN FEEDBACK, TAMPER, SOLAR, POWER GOOD",
                  style: {
                    fontSize: 12,
                  },
                },
              ],
            },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `From: ${moment(StartDate).format(
            //         "DD/MM/YYYY hh:mm:ss"
            //       )} To: ${moment(EndDate).format("DD/MM/YYYY hh:mm:ss")}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: `Downloaded on ${moment().format(
            //         "DD-MM-YYYY hh:mm:ss A"
            //       )}`,
            //       style: {
            //         fontSize: 12,
            //       },
            //     },
            //   ],
            // },
            // {
            //   cells: [
            //     {
            //       colSpan: 29,
            //       value: DeviceName,
            //       style: {
            //         backColor: "#204FAC",
            //         fontColor: "#ffffff",
            //         fontSize: 13,
            //         hAlign: "Center",
            //       },
            //     },
            //   ],
            // },
          ],
        },
      };
      grid.current.excelExport(excelExportProperties);
    }
  };

  const excelHeaderQueryCellInfo = (args: ClickEventArgs) => {
    if (
      (grid.current?.excelExportModule as any).sheet.images === null ||
      (grid.current?.excelExportModule as any).sheet.images === undefined
    ) {
      (grid.current?.excelExportModule as any).sheet.images = [];
    }
    const excelImage: any = {
      //   image: wbpcb_base64,
      row: 1,
      column: 1,
      lastRow: 2,
      lastColumn: 2,
    };
    (grid.current?.excelExportModule as any).sheet.images.push(excelImage);
  };

  const filterOptions: FilterSettingsModel = {
    type: "Excel",
  };

  const toolbar = ["ExcelExport", "Search"];
  const pageSettings: PageSettingsModel = { pageSize: 150 };

  // End DataGrid

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [VehicleSelect, setVehicleSelect] = useState<any[]>([]);

  return (
    <div className="Container">
      <Sidebar />
      <div className="DataGridContainer">
        <h1>Reports / Weighbridge v2</h1>
        <div className="Dropdowns">
          <div className="datePicker">
            <div className="labelDrop">
              <p>Start Date</p>
              <DatePicker
                onChange={(date: Date) => setStartDate(date)}
                value={startDate}
              />
            </div>
            <div className="labelDrop">
              <p>End Date</p>
              <DatePicker
                onChange={(date: Date) => setEndDate(date)}
                value={endDate}
              />
            </div>
          </div>
          <button className="btn">Submit</button>
        </div>
        <div className="Grid">
          <GridComponent
            toolbarClick={toolbarClick}
            ref={(g) => (grid.current = g)}
            filterSettings={filterOptions}
            toolbar={toolbar}
            allowFiltering
            allowTextWrap
            allowSorting
            allowPaging={true}
            pageSettings={pageSettings}
            allowPdfExport
            allowExcelExport
            allowResizing={true}
            excelHeaderQueryCellInfo={excelHeaderQueryCellInfo}
          >
            <ColumnsDirective>
              <ColumnDirective
                width="150"
                field="pm1"
                headerText="Transaction No."
              />
              <ColumnDirective
                width="150"
                field="pm25"
                headerText="Vehicle No."
              />
              <ColumnDirective width="150" field="pm10" headerText="Item No." />
              <ColumnDirective
                width="150"
                field="adc_no2_opt2"
                headerText="Transporter No."
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Challan No."
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Date One"
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Weight One (Kg)"
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Date Two"
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Weight Two (Kg)"
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Net Weight (Kg)"
              />
              <ColumnDirective
                width="150"
                field="adc_so2_opt2"
                headerText="Remarks"
              />
            </ColumnsDirective>
            <Inject
              services={[
                Toolbar,
                ExcelExport,
                PdfExport,
                Filter,
                Sort,
                Resize,
                InfiniteScroll,
                Page,
              ]}
            />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};

export default WeighBridgeV2;
