import axios from "axios";
import moment from "moment";

export interface RoadSweeperDataType {
  sl_no: number;
  date: string;
  vehicalNumber: string;
  numberOfDataPoints: number;
  dead_run: string;
  actual_run: string;
}

export interface getRoadSweeperDataType {
  status: string;
  date: Date[];
}

export const getRoadSweeperData = async (
  vehicleList: string[],
  start_date: Date | null,
  end_date: any
) => {
  var data = JSON.stringify({
    devid: vehicleList,
    start_date: moment(start_date).format("YYYY-MM-DD"),
    end_date: moment(end_date).format("YYYY-MM-DD"),
  });

  var config: any = {
    method: "post",
    url: "http://monster2.distronix.in:1100/api/report/roadSweeper",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  const response: any = await axios(config).catch((err) => {
    console.log(err);
  });
  return response.data.date;
};
